<template>
  <v-row v-if="!isFetching" justify="center" align="center" no-gutters>
    <v-col cols="12">
      <v-card class="px-3">
        <v-row v-if="completedBoards.length != 0">
          <v-col cols="6" class="text-left">
            <span class="caption grey--text font-weight-medium">Title</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">{{ $store.getters['user/user'].user.isLawyer ? 'Earnings' : 'Payment' }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Status</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Actions</span>
          </v-col>
        </v-row>
        <v-row 
          v-for="(board, index) in completedBoards" 
          :key="index" 
          @click="redirect(board._id)"
          class="taskTile task-hover pointer" 
          align="center"
        >
          <v-col cols="6" class="text-left">
            <span class="body-2">{{ board.problem.title | taskTextTruncate }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="body-2">{{ board.amount | taskPHCurrency }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="body-2">{{ board.disputed ? 'Disputed' : 'Finished' }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-menu
              transition="slide-x-transition"
              bottom
              right
            >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, i) in items" :key="i" @click="deleteTask(board._id, index)">
                  <v-icon class="error--text pr-2">mdi-delete</v-icon>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-pagination v-if="pages > 1" v-model="page" :length="pages" @input="get" class="py-2"></v-pagination>
      </v-card>
      <v-row v-if="completedBoards.length == 0" justify="center" align="center" class="pb-12" style="height: 80vh;">
        <v-col cols="3">
          <v-img src="/No_Ongoing_Task.png" class="mx-auto" width="150"></v-img>
          <p class="mb-0 grey--text text-center">You have no previous tasks</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else justify="center" align="center" style="height: 80vh;">
    <v-col cols="4" class="py-16 text-center">
      <v-progress-linear
        color="deep-purple accent-4"
        height="6"
        class="mb-4"
        indeterminate
        rounded
      ></v-progress-linear>
      <p class="mb-0 caption">Fetching Previous Tasks ...</p>
    </v-col>
  </v-row>
</template>

<script>
import BoardService from '@/services/BoardService'
import LegalAdviceService from '@/services/LegalAdviceServices'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import taskMixin from '@/mixins/taskMixin'

export default {
  mixins: [
    taskMixin,
    toLocaleString
  ],
  data () {
    return {
      isFetching: false,
      updateProposalModal: false,
      selectedProposal: '',
      search: '',
      page: 1,
      pages: 0,
      completedBoards: [],
      selected: [],
      items: [
        { title: 'Delete Task' }
      ]
    }
  },
  methods: {
    redirect (adviceId) {
      this.$router.push({ name: 'Board', params: { boardId: adviceId } })
    },
    viewBoard (boardId) {
      this.$router.push({ name: 'Board', params: { boardId } })
    },
    proposedAmount (value) {
      if (value) {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return `Free`
      }
    },
    getCompletedUnhiddenTask () {
      this.isFetching = true
      BoardService.getUnhiddenCompletedTask({ page: this.page })
        .then(res => {
          this.completedBoards = res.data.docs
          this.pages = res.data.pages
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    deleteTask (adviceId, index) {
      this.$swal.queue([{
        title: 'Are you sure?',
        text: 'Task will be deleted completely!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return LegalAdviceService.hideAdvice({ adviceId: adviceId })
            .then(() => {
              this.completedBoards.splice(index, 1)
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully deleted task.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(err => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  filters: {
    phCurrency (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    }
  },
  created () {
    this.getCompletedUnhiddenTask()
  }
}
</script>

<style scoped>
  .task-hover {
    color: black;
  }
  .task-hover:hover {
    color: #5F27CD;
  }
</style>
