<template>
  <div v-if="!isFetching">
    <div v-if="boards.length > 0">
      <v-card v-for="board in boards" :key="board.index" class="pa-1 mt-1 job-feed-hover">
        <v-list-item class="px-2">
          <v-list-item-content @click="redirectTask(board._id)" class="pt-2">
            <v-list-item-title class="body-2 primary--text">{{ board.problem.title | mobileTextTruncate }}</v-list-item-title>
            <v-list-item-subtitle v-if="!board.problem.isAnonymous" class="caption grey--text">
              {{ $store.getters['user/user'].user.isLawyer ? 'Hired by:' : 'Your Lawyer:'}}
              {{ $store.getters['user/user'].user.isLawyer ? board.problem.client.fullName : board.lawyer.fullName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else class="caption">Anonymous Client</v-list-item-subtitle>
          </v-list-item-content>
          <v-flex xs4 class="text-center">
            <span :class="proposedAmount(board.amount) === 'Free Advice' ? 'caption font-weight-medium' : 'caption font-weight-medium'">{{ proposedAmount(board.amount) }}</span>
            <p v-if="board.amount > 0" class="caption grey--text mb-1">Amount</p>
          </v-flex>
        </v-list-item>
        <v-layout row wrap align-center class="px-2 pt-0 pb-0">
          <!-- <v-flex xs4 v-if="board.amount" class="text-center">
            <span v-if="board.amount" class="caption font-weight-medium">{{  statusText(board) }}</span>
            <p class="caption grey--text mb-1">Payment Status</p>
          </v-flex> -->
          <!-- <v-flex xs4 class="text-center">
            <span class="caption">{{ board.limitDate | localDate }}</span>
            <p class="caption grey--text mb-1">Date to finish</p>
          </v-flex> -->
        </v-layout>
      </v-card>
    </div>
    <div v-else style="height: 83vh;" class="pb-5">
      <v-layout row wrap justify-center fill-height align-center class="mr-0">
        <v-flex v-if="!hasError" px-4 class="text-center">
          <p class="headline grey--text mb-1">No Task in Progress</p>
          <p class="subheading grey--text font-weight-light">When your task has a lawyer working on it, you'll see the task here.</p>
        </v-flex>
        <v-flex v-else px-4 class="text-center">
          <p class="headline grey--text mb-1">Error Retrieving Tasks</p>
          <p class="subheading grey--text font-weight-light">There is something wrong with the server.</p>
        </v-flex>
      </v-layout>
    </div>
  </div>
  <div v-else style="height: 83vh;" class="pb-5">
    <v-layout row wrap justify-center fill-height align-center class="mr-0">
      <v-progress-circular
        :size="100"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-layout>
  </div>
</template>

<script>
import BoardService from '@/services/BoardService'
import TaskMixin from '@/mixins/taskMixin'

export default {
  name: 'CurrentTaskMobileIndex',
  mixins: [ TaskMixin ],
  data () {
    return {
      isFetching: false,
      hasError: false,
      boards: '',
      page: 1,
      pages: 0
    }
  },
  methods: {
    redirectTask (boardId) {
      this.$router.push({ name: 'MobileConversation', params: { boardId } })
    },
    getOngoings () {
      this.isFetching = true
      BoardService.getBoards({ page: this.page })
        .then(res => {
          this.boards = res.data.currentBoards.docs
          this.pages = res.data.currentBoards.pages
        })
        .catch(() => {
          this.hasError = true
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    proposedAmount (value) {
      if (value) {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return `Free Advice`
      }
    },
    statusText (board) {
      if (board.payment && board.payment.status === 'Payment Verified' ) {
        return 'Approved'
      }
      else if (board.payment) {
        return board.payment.status
      }
      else if (!board.payment) {
        return 'Pending'
      }
    }
  },
  created () {
    this.getOngoings()
  }
}
</script>

<style scoped>
  .job-feed-hover:hover {
    background-color: #f7f7f7;
  }
</style>
