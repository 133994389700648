<template>
  <div v-if="!isFetching">
    <div v-if="completedBoards.length > 0">
      <v-card v-for="board in completedBoards" :key="board.index" two-line class="pa-1 job-feed-hover mt-1">
        <v-list-item class="px-2">
          <v-list-item-content @click="goToBoard(board._id)">
            <v-list-item-title class="body-2 primary--text">{{ board.problem.title | mobileTextTruncate }}</v-list-item-title>
            <v-list-item-subtitle v-if="!board.problem.isAnonymous" class="caption">
              {{ $store.getters['user/user'].user.isLawyer ? 'Hired by:' : 'Your Lawyer:'}}
              {{ $store.getters['user/user'].user.isLawyer ? board.problem.client.fullName : board.lawyer.fullName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else class="caption">Anonymous Client</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn class="error" dark icon small>
              <v-icon @click="deleteTask(board._id, board.index)" small>delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-card-actions class="px-2 pt-2">
          <v-flex xs6>
            <span class="caption grey--text">{{ $store.getters['user/user'].user.isLawyer ? 'Earnings:' : 'Payment:'}}</span>
            <span class="pl-1 caption font-weight-medium" v-if="$store.getters['user/user'].user.isLawyer">{{ board.payment ? board.payment.amount : 0 | amountReceived }}</span>
            <span class="pl-1 caption font-weight-medium" v-else>{{ board.payment ? board.payment.amount : 0 | taskPHCurrency }}</span>
          </v-flex>
          <v-spacer></v-spacer>
          <span class="caption grey--text">Status:</span>
          <span class="pl-1 caption font-weight-medium">{{ board.isDisputed ? 'Disputed' : 'Finished' }}</span>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else style="height: 83vh;" class="pb-5">
      <v-layout row wrap justify-center fill-height align-center class="mr-0">
        <v-flex v-if="!hasError" px-4 class="text-center">
          <p class="headline grey--text mb-1">No Completed Tasks </p>
          <p class="subheading grey--text font-weight-light">Tasks that are completed either successfully or disputed can be seen here.</p>
        </v-flex>
        <v-flex v-else px-4 class="text-center">
          <p class="headline grey--text mb-1">Error Retrieving Completed Tasks</p>
          <p class="subheading grey--text font-weight-light">There is something wrong with the server.</p>
        </v-flex>
      </v-layout>
    </div>
  </div>
  <div v-else style="height: 83vh;" class="pb-5">
    <v-layout row wrap justify-center fill-height align-center class="mr-0">
      <v-progress-circular
        :size="100"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-layout>
  </div>
</template>

<script>
import LegalAdviceService from '@/services/LegalAdviceServices'
import BoardService from '@/services/BoardService'
import taskMixin from '@/mixins/taskMixin'

export default {
  name: 'PastTaskMobileIndex',
  data () {
    return {
      isFetching: false,
      hasError: false,
      completedBoards: '',
      page: 1,
      pages: 0
    }
  },
  mixins: [
    taskMixin
  ],
  methods: {
    getCompletedUnhiddenTask () {
      this.isFetching = true
      BoardService.getUnhiddenCompletedTask({ page: this.page })
        .then(res => {
          this.completedBoards = res.data.docs
          this.pages = res.data.pages
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    deleteTask (adviceId, index) {
      this.$swal.queue([{
        title: 'Are you sure?',
        text: 'Task will be deleted completely!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return LegalAdviceService.hideAdvice({ adviceId: adviceId })
            .then(() => {
              this.completedBoards.splice(index, 1)
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully deleted task.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(err => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    proposedAmount (value) {
      if (value) {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return `Free`
      }
    },
    goToBoard (boardId) {
      this.$router.push({ name: 'Board', params: { boardId } })
    }
  },
  created () {
    this.getCompletedUnhiddenTask()
  }
}
</script>

<style scoped>
  .job-feed-hover:hover {
    background-color: #f7f7f7;
  }
</style>
