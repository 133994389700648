<template>
  <v-row v-if="!isFetching" align="center" justify="center" no-gutters>
    <v-col cols="12">
      <v-card class="px-3">
        <v-row v-if="disputes.length != 0">
          <v-col cols="6" class="text-left">
            <span class="caption grey--text font-weight-medium">Title</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Date</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Status</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Options</span>
          </v-col>
        </v-row>
        <v-row 
          v-for="(dispute, index) in disputes" 
          :key="index" 
          @click="redirect(dispute._id)"
          class="taskTile task-hover pointer" 
          align="center"
        >
          <v-col cols="6" class="text-left">
            <span class="body-2">{{ dispute.board.problem.title | taskTextTruncate }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="body-2">{{ dispute.createdAt | localDate }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-chip :class="disputeClass(dispute)" dark>{{ disputeStatus(dispute) }}</v-chip>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-menu bottom v-if="!$store.getters['user/user'].user.isLawyer">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="!dispute.isResolve" @click="cancelDispute(dispute._id, dispute.board._id)">
                  <v-icon color="error" class="pr-2">mdi-delete</v-icon>
                  <v-list-item-content>Cancel Dispute</v-list-item-content>
                </v-list-item>
                <v-list-item v-else @click="deleteDispute(props.item)">
                  <v-icon color="error" class="pr-2">mdi-delete</v-icon>
                  <v-list-item-content>Delete Dispute</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu bottom v-if="$store.getters['user/user'].user.isLawyer && dispute.isResolve">
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="deleteDispute(dispute)">
                  <v-icon color="error" class="pr-2">delete</v-icon>
                  <v-list-item-content>Delete Dispute</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-pagination v-if="pages > 1" v-model="page" :length="pages" @input="getDisputes()" class="py-2"></v-pagination>
      </v-card>
      <v-row v-if="disputes.length == 0" justify="center" align="center" class="pb-12" style="height: 80vh;">
        <v-col cols="3">
          <v-img src="/No_Disputes.png" class="mx-auto" width="150"></v-img>
          <p class="mb-0 grey--text text-center">You have no disputed tasks</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else justify="center" align="center" style="height: 80vh;">
    <v-col cols="4" class="py-16 text-center">
      <v-progress-linear
        color="deep-purple accent-4"
        height="6"
        class="mb-4"
        indeterminate
        rounded
      ></v-progress-linear>
      <p class="mb-0 caption">Fetching Disputed Tasks ...</p>
    </v-col>
  </v-row>
</template>

<script>
import toLocaleString from '@/mixins/toLocaleStringMixin'
import taskMixin from '@/mixins/taskMixin'
import fullDateToWords from '@/mixins/momentFullDateToWordsMixin'

export default {
  mixins: [
    taskMixin,
    toLocaleString,
    fullDateToWords
  ],
  data () {
    return {
      search: '',
      page: 1,
      pages: 0,
      selected: []
    }
  },
  methods: {
    redirect (disputeId) {
      this.$router.push({ name: 'Dispute-ViewOne', params: { disputeId } })
    },
    getDisputes () {
      this.$store.dispatch('disputes/GET_USER_DISPUTES', { page: this.page })
        .then(res => {
          this.pages = res.pages
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server.',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
    },
    cancelDispute (disputeId, boardId) {
      this.$swal.queue([{
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          let load = {
            disputeId,
            boardId
          }
          return this.$store.dispatch('disputes/CANCEL_SELECTED_DISPUTE', load)
            .then((res) => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully cancelled the dispute.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    deleteDispute (dispute) {
      this.$swal.queue([{
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('disputes/deleteDispute', dispute)
            .then((res) => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully deleted the dispute.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    disputeStatus (dispute) {
      if (!dispute.isResolve) {
        return 'Pending'
      } else {
        if (dispute.awardedTo === 'Defendant' && this.$store.getters['user/user'].user._id === dispute.defendant) {
          return 'Awarded to you'
        }
        else if (dispute.awardedTo === 'Complainant' && this.$store.getters['user/user'].user._id === dispute.complainant._id) {
          return 'Awarded to you'
        }
        else if (dispute.awardedTo === 'Complainant' && this.$store.getters['user/user'].user._id !== dispute.complainant._id) {
          return 'Awarded to client'
        }
        else if (dispute.awardedTo === 'Defendant' && this.$store.getters['user/user'].user._id !== dispute.defendant) {
          return 'Awarded to lawyer'
        }
      }
    },
    disputeClass (dispute) {
      if (!dispute.isResolve) {
        return 'pending'
      } else {
        if (dispute.awardedTo === 'Defendant' && this.$store.getters['user/user'].user._id === dispute.defendant) {
          return 'success'
        } 
        else if (dispute.awardedTo === 'Complainant' && this.$store.getters['user/user'].user._id === dispute.complainant._id) {
          return 'success'
        }
        else if (dispute.awardedTo === 'Complainant' && this.$store.getters['user/user'].user._id !== dispute.complainant._id) {
          return 'error'
        }
        else if (dispute.awardedTo === 'Defendant' && this.$store.getters['user/user'].user._id !== dispute.defendant) {
          return 'error'
        }
      }
    }
  },
  computed: {
    disputes () {
      return this.$store.getters['disputes/userDisputes']
    },
    isFetching () {
      return this.$store.getters['disputes/isLoading']
    }
  },
  created () {
    this.getDisputes()
  }
}
</script>

<style scoped>
  .task-hover {
    color: black;
  }
  .task-hover:hover {
    color: #5F27CD;
  }
</style>
