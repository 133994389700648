<template>
  <v-row justify="center" :class="$vuetify.breakpoint.mdAndUp ? 'pt-3' : ''" no-gutters>
    <v-col v-if="!$store.getters['user/user'].isFetching" :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'">
      <v-tabs :centered="!$vuetify.breakpoint.mdAndUp" v-model="tabIndex" color="primary" background-color="transparent" class="mb-3">
        <v-tab
          v-for="(name, index) in $store.getters['user/user'].user.isLawyer ? lawyerTabs : clientTabs"
          :key="index"
          v-model="tab"
          @click="showTable(index)"
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col cols="8" v-if="$vuetify.breakpoint.mdAndUp" v-model="tab" class="px-0">
      <PendingTasks v-if="tabIndex === 0 && !$store.getters['user/user'].user.isLawyer" />
      <ActiveBids v-if="tabIndex === 0 && $store.getters['user/user'].user.isLawyer" />
      <CurrentTasks v-else-if="tabIndex === 1" />
      <PastTasks v-else-if="tabIndex === 2" />
      <DisputeTasks v-else-if="tabIndex === 3" />
    </v-col>
    <v-col v-else v-model="tab" cols="12" style="background-color: #F0F2F5;">
      <MobilePendingTasks v-if="tabIndex === 0 && $store.getters['user/user'].user.isLawyer == false" class="px-2" />
      <MobileActiveBids v-if="tabIndex === 0 && $store.getters['user/user'].user.isLawyer == true" class="px-2" />
      <MobileCurrentTasks v-else-if="tabIndex === 1" class="px-2" />
      <MobilePastTasks v-else-if="tabIndex === 2" class="px-2" />
      <MobileDisputeTasks v-else-if="tabIndex === 3" class="px-2" />
    </v-col>
  </v-row>
</template>

<script>
import ActiveBids from '@/components/TaskComponents/Bids/Index'
import CurrentTasks from '@/components/TaskComponents/Current/Index'
import PastTasks from '@/components/TaskComponents/Past/Index'
import PendingTasks from '@/components/TaskComponents/Pending/Index'
import DisputeTasks from '@/components/TaskComponents/Dispute/Index'

import MobileActiveBids from '@/components/TaskComponents/Bids/MobileIndex'
import MobileCurrentTasks from '@/components/TaskComponents/Current/MobileIndex'
import MobilePastTasks from '@/components/TaskComponents/Past/MobileIndex'
import MobilePendingTasks from '@/components/TaskComponents/Pending/MobileIndex'
import MobileDisputeTasks from '@/components/TaskComponents/Dispute/MobileIndex'

export default {
  components: {
    ActiveBids,
    CurrentTasks,
    PastTasks,
    PendingTasks,
    DisputeTasks,
    MobileActiveBids,
    MobileCurrentTasks,
    MobilePastTasks,
    MobileDisputeTasks,
    MobilePendingTasks
  },
  data () {
    return {
      tabs: '',
      tab: null,
      lawyerTabs: ['Proposals', 'Ongoing', 'Previous'],
      clientTabs: ['Posted', 'Ongoing', 'Previous']
    }
  },
  methods: {
    showTable (index) {
      this.$store.dispatch('control/SET_TASK_TAB_INDEX', index)
    },
    initTabs () {
      if (this.$store.getters['user/user'].user.isLawyer) {
        this.tabs = this.lawyerTabs
      } else {
        this.tabs = this.clientTabs
      }
    }
  },
  created () {
    this.initTabs()
  },
  computed: {
    tabIndex: {
      get () {
        return this.$store.getters['control/taskTabIndex']
      },
      set () {
      }
    }
  }
}
</script>

<style>
  .v-slide-group {
    display: -webkit-box;
  }

</style>
