import moment from 'moment'

export default {
  filters: {
    averageBidAmount (value) {
      if (value.length) {
        const length = value.length
        let resultArray = []
        let average = arr => arr.reduce((a, v) => a + v, 0) / arr.length

        for (var i = 0; i < length; i++) {
          if (!value[i].isFree) {
            resultArray.push(value[i].amount)
          }
        }

        return average(resultArray)
      } else {
        return 0
      }
    },
    amountReceived (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return (value - (value * parseFloat(process.env.VUE_APP_SERVICE_FEE))).toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    taskPHCurrency (value) {
      if (!value) {
        value = 0
        return 'Free Advice'
      } else {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    fullDateToWords (value) {
      return value ? moment(value).format('DD MMMM YYYY') : ''
    },
    localDate (value) {
      return value ? moment(value).format('MMMM DD, YYYY') : ''
    },
    textTruncate (value) {
      return value.length < 90 ? value : value.substring(0, 90) + '...'
    },
    taskTextTruncate (value) {
      return value.length < 90 ? value : value.substring(0, 50) + '...'
    },
    mobileTextTruncate (value) {
      return value.length < 30 ? value : value.substring(0, 26) + '...'
    }
  }
}
