<template>
  <div v-if="!isFetching">
    <div v-if="pendings.length > 0">
      <v-card v-for="pending in pendings" :key="pending.index" width="351" class="pa-1 job-feed-hover mt-1">
        <v-list-item class="px-2">
          <v-list-item-content @click="redirectTask(pending._id)">
            <v-list-item-title class="body-2 primary--text">{{ pending.title | mobileTextTruncate }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn class="error" dark icon small>
              <v-icon @click="cancelProblem(pending)" small>delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-card-actions class="px-2 pt-2">
          <v-flex xs3>
            <span class="caption grey--text">Bids </span>
            <span class="pl-1 caption font-weight-medium">{{ pending.bids }}</span>
          </v-flex>
          <!-- <v-flex xs5>
            <span class="caption grey--text">Time Frame </span>
            <span class="pl-1 caption font-weight-medium">{{ pending.daysToFinish }} {{ pending.daysToFinish === 1 ? 'day' : 'days' }}</span>
          </v-flex> -->
          <v-spacer></v-spacer>
          <span class="caption grey--text">Type:</span>
          <span class="pl-1 caption font-weight-medium">{{ pending.problemType }}</span>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else style="height: 83vh;" class="pb-5">
      <v-layout row wrap justify-center fill-height align-center>
        <v-flex px-4 class="text-xs-center">
          <p class="headline grey--text mb-1">No Open Tasks</p>
          <p class="subheading grey--text font-weight-light">When you post a task, you'll see the task here.</p>
          <v-btn depressed large color="primary" to="/post-problem">Post a Task</v-btn>
        </v-flex>
      </v-layout>
    </div>
  </div>
  <div v-else style="height: 83vh;" class="pb-5">
    <v-layout row wrap justify-center fill-height align-center>
      <v-progress-circular
        :size="100"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-layout>
  </div>
</template>

<script>
import ClientProblemService from '@/services/ClientProblemService'
import taskMixin from '@/mixins/taskMixin'

export default {
  name: 'PendingsMobileIndex',
  mixins: [
    taskMixin
  ],
  data () {
    return {
      isFetching: false,
      pendings: '',
      page: 1,
      pages: 0
    }
  },
  methods: {
    getPendings () {
      this.isFetching = true
      ClientProblemService.getPendingProblems({ page: this.page })
        .then(res => {
          this.pendings = res.data.docs
          this.pages = res.data.pages
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    redirectTask (problemId) {
      this.$router.push({ name: 'OpenJob', params: { problemId: problemId } })
    },
    cancelProblem (problem) {
      let index = this.pendings.indexOf(problem)
      this.$swal.queue([{
        text: 'Are you sure you want to cancel this task?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.error,
        cancelButtonColor: this.$vuetify.theme.primary,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          this.$swal.disableButtons()
          return ClientProblemService.cancelProblem({ problemId: problem._id })
            .then(res => {
              this.pendings.splice(index, 1)
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Succesfully cancelled the pending task.',
                confirmButtonColor: '#5F27CD',
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  created () {
    this.getPendings()
  }
}
</script>
