<template>
  <v-row v-if="!isFetching" justify="center" style="height: 80vh;" no-gutters>
    <v-col cols="12">
      <v-card class="px-3">
        <v-row v-if="proposals.length != 0">
          <v-col cols="6" class="text-left">
            <span class="caption grey--text font-weight-medium">Title</span>
          </v-col>
          <v-col cols="1" class="text-center">
            <span class="caption grey--text font-weight-medium">Bids</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">My Bid</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Date</span>
          </v-col>
          <v-col cols="1" class="text-center">
            <span class="caption grey--text font-weight-medium">Options</span>
          </v-col>
        </v-row>
        <v-row
           v-for="(proposal, index) in proposals"
          :key="index" 
          @click="redirect(proposal.problem._id)"
          class="taskTile task-hover pointer" 
          align="center"
        >
          <v-col cols="6" class="text-left">
            <span class="body-2">{{ proposal.problem.title | taskTextTruncate }}</span>
          </v-col>
          <v-col cols="1" class="text-center">
            <span class="body-2">{{ proposal.problem.proposals.length }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="body-2">{{ proposedAmount(proposal.amount) }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="body-2">{{ proposal.problem.proposals[0].createdAt | localDate }}</span>
          </v-col>
          <v-col cols="1" class="text-center">
            <v-menu v-if="proposal.status !== 'Approved' && proposal.type !== 'Legal Advice'" bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="updateProposalModal = true, selectedProposal = proposal">
                  <v-icon color="primary" class="pr-2">mdi-pencil</v-icon>
                  <v-list-item-content>Edit Proposal</v-list-item-content>
                </v-list-item>
                <v-list-item @click="deleteProposal(proposal._id, proposal.problem._id)">
                  <v-icon color="error" class="pr-2">mdi-delete</v-icon>
                  <v-list-item-content>Retract Proposal</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-pagination v-if="pages > 1" v-model="page" :length="pages"></v-pagination>
      </v-card>
      <v-row v-if="proposals.length == 0" justify="center" align="center" class="pb-12" style="height: 80vh;">
        <v-col cols="3" class="text-center">
          <img src="@/assets/NoProposals.png" class="mx-auto" width="150" />
          <p class="mb-0 grey--text text-center">You have no proposals</p>
        </v-col>
      </v-row>
    </v-col>
    <UpdateProposal
      v-if="updateProposalModal"
      :visible="updateProposalModal"
      :proposalId="selectedProposal._id"
      :message="selectedProposal.message"
      :amount="selectedProposal.amount"
      :followUps="selectedProposal.followUps"
      @close="updateProposalModal = false"
    />
  </v-row>
  <v-row v-else justify="center" align="center" style="height: 80vh;">
    <v-col cols="4" class="py-16 text-center">
      <v-progress-linear
        color="deep-purple accent-4"
        height="6"
        class="mb-4"
        indeterminate
        rounded
      ></v-progress-linear>
      <p class="mb-0 caption">Fetching Proposed Tasks ...</p>
    </v-col>
  </v-row>
</template>

<script>
import UpdateProposal from '@/components/ProposalComponents/UpdateProposal'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import taskMixin from '@/mixins/taskMixin'

export default {
  components: {
    UpdateProposal
  },
  mixins: [
    taskMixin,
    toLocaleString
  ],
  data () {
    return {
      updateProposalModal: false,
      selectedProposal: '',
      search: '',
      page: 1,
      pages: 1,
      selected: []
    }
  },
  methods: {
    redirect (proposalId) {
      this.$router.push({ name: 'OpenJob', params: {  problemId: proposalId } })
    },
    proposedAmount (value) {
      if (value) {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return `Free`
      }
    },
    deleteProposal (proposalId, jobId) {
      this.$swal.queue([{
        title: 'Are you sure?',
        text: "You will not revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('proposals/DELETE_USER_PROPOSAL', proposalId)
            .then(() => {
              this.$store.dispatch('jobs/setCancelJob', { jobId })
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully retracted the selected proposal.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  computed: {
    proposals () {
      return this.$store.getters['proposals/userProposals']
    },
    isFetching () {
      return this.$store.getters['proposals/isLoading']
    }
  },
  filters: {
    phCurrency (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    }
  },
  created () {
    this.$store.dispatch('proposals/GET_USER_PROPOSALS', this.$store.getters['user/user'].user._id)
  }
}
</script>

<style scoped>
  .task-hover {
    color: black;
  }
  .task-hover:hover {
    color: #5F27CD;
  }
</style>
