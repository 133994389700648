<template>
  <v-row v-if="!isFetching" justify="center" align="center" no-gutters>
    <v-col cols="12">
      <v-card v-if="boards.length != 0" class="px-3">
        <v-row>
          <v-col cols="4" class="text-left">
            <span class="caption grey--text font-weight-medium">Title</span>
          </v-col>
          <v-col cols="4" class="text-center">
            <span class="caption grey--text font-weight-medium">{{ $store.getters['user/user'].user.isLawyer ? 'Client Name' : 'Lawyer Name' }}</span>
          </v-col>
          <v-col cols="4" class="text-center">
            <span class="caption grey--text font-weight-medium">Amount</span>
          </v-col>
          <!-- <v-col cols="3" class="text-center">
            <span class="caption grey--text font-weight-medium">Payment Status</span>
          </v-col> -->
          <!-- <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Date to finish</span>
          </v-col> -->
        </v-row>
        <div v-for="(board, index) in boards" :key="index">
          <router-link :to="{ name: 'Board', params: { boardId: board._id } }" class="task-hover" style="text-decoration: none;">
            <v-row class="taskTile" align="center">
              <v-col cols="4" class="text-left">
                <span class="body-2">{{ board.problem.title | taskTextTruncate }}</span>
              </v-col>
              <v-col cols="4" class="text-center">
                <span class="body-2">{{ userName(board) | slice }}</span>
              </v-col>
              <v-col cols="4" class="text-center">
                <span class="body-2">{{ proposedAmount(board.amount) }}</span>
              </v-col>
              <!-- <v-col cols="3" class="text-center">
                <span v-if="board.amount != 0" class=" body-2">{{ board.payment ? board.payment.status : 'Pending Payment' }}</span>
                <span v-else class="body-2">Approved</span>
              </v-col> -->
              <!-- <v-col cols="2" class="text-center">
                <span class="body-2">{{ board.limitDate | localDate }}</span>
              </v-col> -->
            </v-row>
          </router-link>
        </div>
        <v-col v-if="pages > 1" class="py-2">
          <v-pagination v-model="page" :length="pages" @input="getOngoings()"></v-pagination>
        </v-col>
      </v-card>
      <v-row v-if="boards.length == 0" justify="center" align="center" class="pb-12" style="height: 80vh;">
        <v-col cols="3">
          <v-img src="/No_Ongoing_Task.png" class="mx-auto" width="150"></v-img>
          <p class="mb-0 grey--text text-center">You have no ongoing tasks</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else justify="center" align="center" style="height: 80vh;">
    <v-col cols="4" class="py-16 text-center">
      <v-progress-linear
        color="deep-purple accent-4"
        height="6"
        class="mb-4"
        indeterminate
        rounded
      ></v-progress-linear>
      <p class="mb-0 caption">Fetching Ongoing Tasks ...</p>
    </v-col>
  </v-row>
</template>

<script>
import BoardService from '@/services/BoardService'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import taskMixin from '@/mixins/taskMixin'

export default {
  mixins: [
    taskMixin,
    toLocaleString
  ],
  data () {
    return {
      isFetching: false,
      updateProposalModal: false,
      selectedProposal: '',
      search: '',
      boards: [],
      pages: 1,
      page: 1,
      selected: []
    }
  },
  methods: {
    viewBoard (boardId) {
      this.$router.push({ name: 'Board', params: { boardId } })
    },
    proposedAmount (value) {
      if (value) {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return `Free`
      }
    },
    getOngoings () {
      this.isFetching = true
      BoardService.getBoards({ page: this.page })
        .then(res => {
          this.boards = res.data.currentBoards.docs
          this.pages = res.data.currentBoards.pages
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    userName (item) {
      if (!this.$store.getters['user/user'].user.isLawyer) {
        return item.lawyer.fullName
      }
      else if (this.$store.getters['user/user'].user.isLawyer && item.problem.isAnonymous) {
        return 'Anonymous User'
      }
      else if (this.$store.getters['user/user'].user.isLawyer && !item.problem.isAnonymous) {
        return item.problem.client.fullName
      }
    }
  },
  filters: {
    phCurrency (value) {
      if (!value) {
        value = 0
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      }
    },
    slice: function (value) {
      if (!value) return ''
      if (/\s/.test(value)) {
        value = value.toString()
        return value.substr(0, value.indexOf(' '))
      } else {
        return value
      }
    }
  },
  created () {
    this.getOngoings()
  }
}
</script>

<style scoped>
  .task-hover {
    color: black;
  }
  .task-hover:hover {
    color: #5F27CD;
  }
</style>
