<template>
  <div v-if="!isFetching">
    <div v-if="disputes.length > 0">
      <v-card v-for="dispute in disputes" :key="dispute.index" width="351" class="mt-1 pa-1 job-feed-hover">
        <v-row align="center" class="px-2" no-gutters>
          <v-col cols="8" class="pr-2">
            <v-list-item-content @click="goToBoard(dispute._id)">
              <v-list-item-title class="body-2 primary--text">{{ dispute.board.problem.title | mobileTextTruncate }}</v-list-item-title>
            </v-list-item-content>
          </v-col>
          <v-col cols="4">
            <span class="caption grey--text">Status <span :class="disputeClass(dispute)">{{ disputeStatus(dispute) }}</span></span>
          </v-col>
        </v-row>
        <v-card-actions class="px-2 pt-4">
          <span class="caption grey--text">{{ dispute.createdAt | localDate }}</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!dispute.isResolve && !$store.getters['user/user'].user.isLawyer"
            small
            depressed
            color="error"
            @click="cancelDispute(dispute._id, dispute.board._id)"
          >Cancel Dispute</v-btn>
          <v-btn
            v-if="dispute.isResolve"
            small
            depressed
            color="error"
            @click="deleteDispute(dispute)"
          >Delete Dispute</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else style="height: 83vh;" class="pb-5">
      <v-layout row wrap justify-center fill-height align-center class="mr-0">
        <v-flex v-if="!hasError" px-4 class="text-center">
          <p class="headline grey--text mb-1">No Tasks Disputed</p>
          <p class="subheading grey--text font-weight-light">Disputed tasks can be seen here.</p>
        </v-flex>
        <v-flex v-else px-4 class="text-center">
          <p class="headline grey--text mb-1">Error Retrieving Disputed Tasks</p>
          <p class="subheading grey--text font-weight-light">There is something wrong with the server.</p>
        </v-flex>
      </v-layout>
    </div>
  </div>
  <div v-else style="height: 83vh;" class="pb-5">
    <v-layout row wrap justify-center fill-height align-center class="mr-0">
      <v-progress-circular
        :size="100"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-layout>
  </div>
</template>

<script>
import taskMixin from '@/mixins/taskMixin'

export default {
  name: 'DisputeMobileIndex',
  mixins: [
    taskMixin
  ],
  data () {
    return {
      hasError: false,
      page: 1,
      pages: 0
    }
  },
  methods: {
    goToBoard(disputeId) {
      this.$router.push({ name: 'Dispute-ViewOne', params: { disputeId } })
    },
    disputeStatus (dispute) {
      if (!dispute.isResolve) {
        return 'Pending'
      } else {
        if (dispute.awardedTo === 'Defendant' && this.$store.getters['user/user'].user._id === dispute.defendant) {
          return 'Awarded to you'
        }
        else if (dispute.awardedTo === 'Complainant' && this.$store.getters['user/user'].user._id === dispute.complainant._id) {
          return 'Awarded to you'
        }
        else if (dispute.awardedTo === 'Complainant' && this.$store.getters['user/user'].user._id !== dispute.complainant._id) {
          return 'Awarded to client'
        }
        else if (dispute.awardedTo === 'Defendant' && this.$store.getters['user/user'].user._id !== dispute.defendant) {
          return 'Awarded to lawyer'
        }
      }
    },
    disputeClass (dispute) {
      if (!dispute.isResolve) {
        return 'pending--text caption'
      } else {
        if (dispute.awardedTo === 'Defendant' && this.$store.getters['user/user'].user._id === dispute.defendant) {
          return 'success--text'
        } 
        else if (dispute.awardedTo === 'Complainant' && this.$store.getters['user/user'].user._id === dispute.complainant._id) {
          return 'success--text'
        }
        else if (dispute.awardedTo === 'Complainant' && this.$store.getters['user/user'].user._id !== dispute.complainant._id) {
          return 'error--text'
        }
        else if (dispute.awardedTo === 'Defendant' && this.$store.getters['user/user'].user._id !== dispute.defendant) {
          return 'error--text'
        }
      }
    },
    cancelDispute (disputeId, boardId) {
      this.$swal.queue([{
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          let load = {
            disputeId,
            boardId
          }
          return this.$store.dispatch('disputes/CANCEL_SELECTED_DISPUTE', load)
            .then((res) => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully cancelled the dispute',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    deleteDispute (dispute) {
      this.$swal.queue([{
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('disputes/deleteDispute', dispute)
            .then((res) => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully deleted the dispute.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  created () {
    this.$store.dispatch('disputes/GET_USER_DISPUTES', { page: this.page })
      .then(res => {
        this.pages = res.pages
      })
      .catch(() => {
        this.hasError = true
      })
  },
  computed: {
    disputes () {
      return this.$store.getters['disputes/userDisputes']
    },
    isFetching () {
      return this.$store.getters['disputes/isLoading']
    }
  }
}
</script>

<style scoped>
  .job-feed-hover:hover {
    background-color: #f7f7f7;
  }
</style>
