<template>
  <div v-if="!isFetching">
    <v-card v-for="proposal in proposals" :key="proposal.index" class="pa-1 mt-1">
      <v-list-item class="px-3">
        <v-list-item-content @click="redirectTask(proposal.problem._id)">
          <v-list-item-title class="body-2 primary--text">{{ proposal.problem.title | mobileTextTruncate }}</v-list-item-title>
          <v-list-item-subtitle class="caption">{{ proposal.problem.proposals[0].createdAt | localDate }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="deleteProposal(proposal._id)">
                <v-icon color="error" class="pr-2">delete</v-icon>
                <v-list-item-content class="body-2">Retract Proposal</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
      <v-card-actions class="px-3 pt-0">
        <span class="caption grey--text">Bids:</span>
        <span class="pl-1 caption font-weight-medium">{{ proposal.problem.proposals.length }}</span>
        <v-spacer></v-spacer>
        <span class="caption grey--text">My Bid:</span>
        <span class="pl-1 caption font-weight-medium">{{ proposedAmount(proposal.amount) }}</span>
        <v-spacer></v-spacer>
        <span class="caption grey--text">Avg Bid:</span>
        <span class="pl-1 caption font-weight-medium">{{ proposal.problem.proposals | averageBidAmount | taskPHCurrency }}</span>
      </v-card-actions>
    </v-card>
    <!-- <div v-else style="height: 83vh;" class="pb-5">
      <v-layout row wrap justify-center fill-height align-center>
        <v-flex v-if="!hasError" px-4 class="text-center">
          <p class="headline grey--text mb-1">No Proposals</p>
          <p class="subheading grey--text font-weight-light">When you propose a task, you'll see the task here.</p>
          <v-btn depressed large color="primary" to="/jobs">Find Task</v-btn>
        </v-flex>
        <v-flex v-else px-4 class="text-xs-center">
          <p class="headline grey--text mb-1">Error Retrieving Proposals</p>
          <p class="subheading grey--text font-weight-light">There is something wrong with the server.</p>
        </v-flex>
      </v-layout>
    </div> -->
  </div>
  <div v-else style="height: 83vh;" class="pb-5">
    <v-layout row wrap justify-center fill-height align-center>
      <v-progress-circular
        :size="100"
        :width="5"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-layout>
  </div>
</template>

<script>
import taskMixin from '@/mixins/taskMixin'

export default {
  name: 'ProposalsMobileIndex',
  mixins: [
    taskMixin
  ],
  data () {
    return {
      hasError: false
    }
  },
  methods: {
    redirectTask (problemId) {
      this.$router.push({ name: 'OpenJob', params: { problemId: problemId } })
    },
    proposedAmount (value) {
      if (value) {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return `Free`
      }
    },
    deleteProposal (proposalId) {
      this.$swal.queue([{
        title: 'Are you sure?',
        text: "You will not revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8388A5',
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          return this.$store.dispatch('proposals/DELETE_USER_PROPOSAL', proposalId)
            .then(() => {
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Successfully retracted the selected proposal.',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    }
  },
  computed: {
    proposals () {
      return this.$store.getters['proposals/userProposals']
    },
    isFetching () {
      return this.$store.getters['proposals/isLoading']
    }
  },
  created () {
    this.$store.dispatch('proposals/GET_USER_PROPOSALS', this.$store.getters['user/user'].user._id)
      .catch(() => {
        this.hasError = true
      })
  }
}
</script>
