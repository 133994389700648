<template>
  <v-row v-if="!isFetching" justify="center" align="center" no-gutters>
    <v-col cols="12">
      <v-card v-if="pendings.length > 0" class="px-3">
        <v-row>
          <v-col cols="6">
            <span class="caption grey--text font-weight-medium">Title</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Bids</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Type</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="caption grey--text font-weight-medium">Options</span>
          </v-col>
        </v-row>
        <v-row 
          v-for="(pending, index) in pendings" 
          :key="index" @click="redirect(pending._id)" 
          class="task-hover taskTile pointer" 
          align="center"
        >
          <v-col cols="6">
            <span class="body-2">{{ pending.title | taskTextTruncate }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="body-2">{{ pending.bids }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <span class="body-2">{{ pending.problemType }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-menu bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="cancelJob(pending)">
                  <v-icon color="error" class="pr-2">delete</v-icon>
                  <v-list-item-content>Cancel Task</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-col v-if="pages > 1" class="py-2">
          <v-pagination v-model="page" :length="pages" @input="getPendings()"></v-pagination>
        </v-col>
      </v-card>
      <v-row v-else justify="center" align="center" class="pb-12" style="height: 80vh;">
        <v-col cols="3">
          <v-img src="/No_Ongoing_Task.png" class="mx-auto" width="150"></v-img>
          <p class="mb-0 grey--text text-center">You have no posted tasks</p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else justify="center" align="center" style="height: 80vh;">
    <v-col cols="4" class="py-16 text-center">
      <v-progress-linear
        color="deep-purple accent-4"
        height="6"
        class="mb-4"
        indeterminate
        rounded
      ></v-progress-linear>
      <p class="mb-0 caption">Fetching Posted Tasks ...</p>
    </v-col>
  </v-row>
</template>

<script>
import ClientProblemService from '@/services/ClientProblemService'
import toLocaleString from '@/mixins/toLocaleStringMixin'
import taskMixin from '@/mixins/taskMixin'

export default {
  mixins: [
    taskMixin,
    toLocaleString
  ],
  data () {
    return {
      isFetching: false,
      pendings: [],
      search: '',
      selected: [],
      page: 1,
      pages: 0
    }
  },
  methods: {
    redirect (adviceId) {
      this.$router.push({ name: 'OpenJob', params: { problemId: adviceId } })
    },
    getPendings () {
      this.isFetching = true
      ClientProblemService.getPendingProblems({ page: this.page })
        .then(res => {
          this.pendings = res.data.docs
          this.pages = res.data.pages
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            text: 'Something went wrong with the server!',
            confirmButtonColor: this.$vuetify.theme.primary,
            onOpen: () => { document.activeElement.blur() }
          })
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    cancelJob (problem) {
      let index = this.pendings.indexOf(problem)
      this.$swal.queue([{
        text: 'Are you sure you want to cancel this task?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: this.$vuetify.theme.error,
        cancelButtonColor: this.$vuetify.theme.primary,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        onOpen: () => { document.activeElement.blur() },
        preConfirm: () => {
          this.$swal.disableButtons()
          return ClientProblemService.cancelProblem({ problemId: problem._id })
            .then(res => {
              this.pendings.splice(index, 1)
              this.$swal.insertQueueStep({
                type: 'success',
                text: 'Succesfully cancelled the pending task.',
                confirmButtonColor: '#5F27CD',
                onOpen: () => { document.activeElement.blur() }
              })
            })
            .catch(() => {
              this.$swal.insertQueueStep({
                type: 'error',
                text: 'Something went wrong with the server!',
                confirmButtonColor: this.$vuetify.theme.primary,
                onOpen: () => { document.activeElement.blur() }
              })
            })
        }
      }])
    },
    proposedAmount (value) {
      if (value) {
        return value.toLocaleString('en-PH', { style: 'currency', currency: 'PHP' })
      } else {
        return `Free`
      }
    }
  },
  created () {
    this.getPendings()
  }
}
</script>

<style scoped>
  .task-hover {
    color: black;
  }
  .task-hover:hover {
    color: #5F27CD;
  }
</style>
